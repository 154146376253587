/* OpenSans */

/* Thin 100 */
/* Extra-light 200 */
/* Light 300 */

@font-face {
    font-family: 'OpenSans';
    src: url('OpenSans-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'OpenSans';
    src: url('OpenSans-LightItalic.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
}

/* Regular 400 (Normal) */

@font-face {
    font-family: 'OpenSans';
    src: url('OpenSans-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'OpenSans';
    src: url('OpenSans-Italic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
}

/* Medium 500 */

@font-face {
    font-family: 'OpenSans';
    src: url('OpenSans-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'OpenSans';
    src: url('OpenSans-MediumItalic.ttf') format('truetype');
    font-weight: 500;
    font-style: italic;
}

/* Semi-bold 600 */

@font-face {
    font-family: 'OpenSans';
    src: url('OpenSans-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'OpenSans';
    src: url('OpenSans-SemiBoldItalic.ttf') format('truetype');
    font-weight: 600;
    font-style: italic;
}

/* Bold 700 */

@font-face {
    font-family: 'OpenSans';
    src: url('OpenSans-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'OpenSans';
    src: url('OpenSans-BoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
}

/* Extra-bold 800 */

@font-face {
    font-family: 'OpenSans';
    src: url('OpenSans-ExtraBold.ttf') format('truetype');
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: 'OpenSans';
    src: url('OpenSans-ExtraBoldItalic.ttf') format('truetype');
    font-weight: 800;
    font-style: italic;
}

/* Black 900 (Heavy) */